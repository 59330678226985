import React, { FC, useEffect, useState } from 'react'
import { Button, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { useDispatch } from 'react-redux'
import { useForm } from '@inertiajs/react'
import {
  updateHubVisitorLeadId,
  updateShowHubAddVisitorLeadModal,
  updateVisitorLeadFormSubmitted,
} from '@/Store/Hub/hubVisitorLeadSlice'
import XSelect from '@/Mantine/XSelect'
import XTextInput from '@/Mantine/XTextInput'
import useLocation from '@/Hooks/useLocation'
import useFlash from '@/Hooks/useFlash'
import { Checkbox, LoadingOverlay, SelectItem } from '@mantine/core'
import axios from 'axios'
import XPhoneInput from '@/Mantine/XPhoneInput'
import { getOrderOfFieldByLabel, getSelectedOfFieldByLabel } from '@/helpers'
import XDatePicker from '@/Mantine/XDatePicker'
import moment from 'moment/moment'
import Divider from '../../../XelaReact/Divider/Divider'
import { EntitySectionData } from '@/Types/EntitySubTypes'
import useMeta from '@/Hooks/useMeta'
import useAuth from '@/Hooks/useAuth'
import { updateShowHubAddLeadModal } from '@/Store/Hub/hubLeadSlice'
import Agent = App.Models.Agent

const AddNewVisitorLeadFromHubForm: FC = () => {
  const flash = useFlash()
  const meta = useMeta()
  const auth = useAuth()
  const dispatch = useDispatch()
  const location = useLocation()
  const [loading, setLoading] = useState(false)

  const [relationshipManagers, setRelationshipManagers] = useState<SelectItem[]>([])
  const [countries, setCountries] = useState<SelectItem[]>([])
  const [fieldsData] = useState<EntitySectionData>(meta.entityForms['hub_visitor_lead_form'])
  const [categoryData, setCategoryData] = useState<Record<string, boolean>>({})

  const { data, setData, post, errors, reset, recentlySuccessful, clearErrors } = useForm<{
    lead_source: string
    given_name: string
    surname: string
    phone: string
    email: string
    country: string
    country_code: string
    country_calling_code: string
    travel_start_date: string
    travel_end_date: string
    travel_country: string
    travel_country_code: string
    service_type: string
    middle_name: string
    date_of_birth: string
    gender: string
    martial_status: string
    alternate_phone: string
    alternate_country: string
    alternate_country_code: string
    alternate_country_calling_code: string
    passport_number: string
    passport_nationality: string
    passport_issue_date: string
    passport_expiry_date: string
    passport_issue_place: string
    passport_birth_place: string
    previous_passport_number: string
    work_experience_one_company_name: string
    work_experience_one_job_title: string
    work_experience_one_is_still_working: boolean
    work_experience_one_start_date: string
    work_experience_one_end_date: string
    father_name: string
    father_first_name: string
    father_last_name: string
    father_phone: string
    father_country_calling_code: string
    father_country_code: string
    father_country: string
    father_email: string
    father_dob: string
    father_occupation: string
    father_address: string
    father_aadhaar_uid: string
    mother_name: string
    mother_first_name: string
    mother_last_name: string
    mother_phone: string
    mother_country_calling_code: string
    mother_country_code: string
    mother_country: string
    mother_email: string
    mother_dob: string
    mother_occupation: string
    mother_address: string
    mother_aadhaar_uid: string
    marriage_date: string
    b2b_relationship_manager_id: string | null
  }>({
    lead_source: 'Sub-Agent',
    given_name: '',
    surname: '',
    phone: '',
    email: '',
    country: 'India',
    country_code: 'IN',
    country_calling_code: '+91',
    travel_start_date: '',
    travel_end_date: '',
    travel_country: '',
    travel_country_code: '',
    service_type: '',
    middle_name: '',
    date_of_birth: '',
    gender: 'Male',
    martial_status: 'Not Married',
    alternate_phone: '',
    alternate_country: 'India',
    alternate_country_code: 'IN',
    alternate_country_calling_code: '+91',
    passport_number: '',
    passport_nationality: '',
    passport_issue_date: '',
    passport_expiry_date: '',
    passport_issue_place: '',
    passport_birth_place: '',
    previous_passport_number: '',
    work_experience_one_company_name: '',
    work_experience_one_job_title: '',
    work_experience_one_is_still_working: false,
    work_experience_one_start_date: '',
    work_experience_one_end_date: '',
    father_name: '',
    father_first_name: '',
    father_last_name: '',
    father_phone: '',
    father_country_calling_code: '+91',
    father_country_code: 'IN',
    father_country: 'India',
    father_email: '',
    father_dob: '',
    father_occupation: '',
    father_address: '',
    father_aadhaar_uid: '',
    mother_name: '',
    mother_first_name: '',
    mother_last_name: '',
    mother_phone: '',
    mother_country_calling_code: '+91',
    mother_country_code: 'IN',
    mother_country: 'India',
    mother_email: '',
    mother_dob: '',
    mother_occupation: '',
    mother_address: '',
    mother_aadhaar_uid: '',
    marriage_date: '',
    b2b_relationship_manager_id: null,
  })

  useEffect(() => {}, [fieldsData])

  useEffect(() => {
    if (flash.action === 'visitor-lead:created' && recentlySuccessful) {
      const payload = flash.payload as {
        visitor_lead_id?: number
      } | null

      if (payload) {
        if (payload.visitor_lead_id) {
          dispatch(updateHubVisitorLeadId(payload.visitor_lead_id))
          dispatch(updateVisitorLeadFormSubmitted(true))
        } else {
          dispatch(updateShowHubAddVisitorLeadModal(false))
          dispatch(updateShowHubAddLeadModal(false))
        }
        clearErrors()
        reset()
      }
    }
  }, [flash.timestamp])

  useEffect(() => {
    if (location.country_code) {
      setData((prevData) => ({
        ...prevData,
        country: location.country_name,
        country_code: location.country_code,
        country_calling_code: location.country_calling_code,
      }))
    }
  }, [location])

  useEffect(() => {
    axios
      .post<Record<string, string>>('/select/meta-options', {
        entity_name: 'countries',
        column_name: 'country',
        with_key: true,
      })
      .then((response) => {
        const countryList = Object.keys(response.data).map((key) => {
          return {
            value: key,
            label: response.data[key],
          }
        })

        setCountries(countryList)
      })
      .catch((error) => {
        console.error('Error fetching countries:', error)
      })
  }, [])

  useEffect(() => {
    if (fieldsData) {
      const categoryData: Record<string, boolean> = {}
      Object.entries(fieldsData).forEach(([category, formItems]) => {
        categoryData[category] = formItems.some((formItem) => formItem.selected)
      })
      setCategoryData(categoryData)
    }
  }, [fieldsData])

  useEffect(() => {
    const allRelationshipManagers: SelectItem[] = []

    const user = auth.user as Agent
    if (user.relationship_manager !== null) {
      allRelationshipManagers.push({
        value: String(user?.relationship_manager?.id),
        label: user?.relationship_manager?.name,
      })
    }

    if (user.relationship_manager_one !== null) {
      allRelationshipManagers.push({
        value: String(user?.relationship_manager_one?.id),
        label: user?.relationship_manager_one?.name,
      })
    }

    if (user.relationship_manager_two !== null) {
      allRelationshipManagers.push({
        value: String(user?.relationship_manager_two?.id),
        label: user?.relationship_manager_two?.name,
      })
    }

    if (user.relationship_manager_three !== null) {
      allRelationshipManagers.push({
        value: String(user?.relationship_manager_three?.id),
        label: user?.relationship_manager_three?.name,
      })
    }

    if (auth.agency.id === 1111178) {
      if (user.relationship_manager_four !== null) {
        allRelationshipManagers.push({
          value: String(user?.relationship_manager_four?.id),
          label: user?.relationship_manager_four?.name,
        })
      }

      if (user.relationship_manager_five !== null) {
        allRelationshipManagers.push({
          value: String(user?.relationship_manager_five?.id),
          label: user?.relationship_manager_five?.name,
        })
      }

      if (user.relationship_manager_six !== null) {
        allRelationshipManagers.push({
          value: String(user?.relationship_manager_six?.id),
          label: user?.relationship_manager_six?.name,
        })
      }

      if (user.relationship_manager_seven !== null) {
        allRelationshipManagers.push({
          value: String(user?.relationship_manager_seven?.id),
          label: user?.relationship_manager_seven?.name,
        })
      }
    }

    // Unique Relationship Managers
    const uniqueRelationshipManagers = allRelationshipManagers.filter((item, index, self) => {
      return index === self.findIndex((t) => t.value === item.value)
    })

    if (uniqueRelationshipManagers && uniqueRelationshipManagers.length > 0) {
      setRelationshipManagers(uniqueRelationshipManagers)
    }
  }, [auth.user])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        position: 'relative',
        width: '100%',
        height: '100%',
        gap: '12px',
      }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          height: 0,
          gap: '12px',
          width: '100%',
          overflowY: 'auto',
          padding: '1px',
          paddingRight: '10px',
          margin: '-1px',
        }}
      >
        <VStack spacing={'12px'}>
          <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
            Personal Details
          </Typography>

          <div className="grid grid-cols-2 w-full gap-4 items-start p-[1px]">
            <HStack
              style={{
                order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'given_name'),
              }}
            >
              <XTextInput
                data-autofocus
                required
                label={'Given Name'}
                error={errors.given_name}
                value={data.given_name}
                onChange={(event) => {
                  setData((prevData) => ({
                    ...prevData,
                    given_name: event.target.value,
                  }))
                }}
              />
            </HStack>
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'middle_name') && (
              <HStack
                style={{
                  order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'middle_name'),
                }}
              >
                <XTextInput
                  data-autofocus
                  label={'Middle Name'}
                  error={errors.middle_name}
                  value={data.middle_name}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      middle_name: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'surname') && (
              <HStack
                style={{
                  order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'surname'),
                }}
              >
                <XTextInput
                  label={'Surname'}
                  error={errors.surname}
                  value={data.surname}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      surname: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'phone') && (
              <HStack
                style={{
                  order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'phone'),
                }}
              >
                <XPhoneInput
                  error={errors.phone}
                  dialCode={data.country_calling_code}
                  countryCode={data.country_code}
                  initialValue={data.phone}
                  onChange={(number, dialCode, countryCode, country) => {
                    setData((prevData) => ({
                      ...prevData,
                      phone: number,
                      country_calling_code: dialCode,
                      country_code: countryCode,
                      country: country,
                    }))
                  }}
                  //checkMobileRemoteValidation={true}
                ></XPhoneInput>
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'email') && (
              <HStack
                style={{
                  order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'email'),
                }}
              >
                <XTextInput
                  label={'Email'}
                  error={errors.email}
                  value={data.email}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      email: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'gender') && (
              <HStack
                style={{
                  order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'gender'),
                }}
              >
                <XSelect
                  searchable
                  label={'Gender'}
                  error={errors.gender}
                  value={data.gender}
                  data={['Male', 'Female', 'Gender Fluid/Non Binary']}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        gender: value,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'date_of_birth') && (
              <HStack
                style={{
                  order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'date_of_birth'),
                }}
              >
                <XDatePicker
                  dateParser={(dateString) => {
                    return moment(dateString, 'DD/MM/YYYY').toDate()
                  }}
                  value={data.date_of_birth ? moment(data.date_of_birth as string, 'YYYY-MM-DD').toDate() : null}
                  width="100%"
                  placeholder="DD/MM/YYYY"
                  valueFormat={'DD/MM/YYYY'}
                  maxDate={new Date()}
                  label={'Date of Birth'}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        date_of_birth: moment(value).format('YYYY-MM-DD'),
                      }))
                    }
                  }}
                  error={errors.date_of_birth}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'marital_status') && (
              <HStack
                style={{
                  order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'marital_status'),
                }}
              >
                <XSelect
                  searchable
                  label={'Marital Status'}
                  error={errors.martial_status}
                  value={data.martial_status}
                  data={['Married', 'Not Married']}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        martial_status: value,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'marriage_date') && (
              <HStack
                style={{
                  order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'marriage_date'),
                }}
              >
                <XDatePicker
                  dateParser={(dateString) => {
                    return moment(dateString, 'DD/MM/YYYY').toDate()
                  }}
                  value={data.marriage_date ? moment(data.marriage_date as string, 'YYYY-MM-DD').toDate() : null}
                  width="100%"
                  placeholder="DD/MM/YYYY"
                  valueFormat={'DD/MM/YYYY'}
                  label={'Marriage Anniversary'}
                  maxDate={new Date()}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        marriage_date: moment(value).format('YYYY-MM-DD'),
                      }))
                    }
                  }}
                  error={errors.marriage_date}
                />
              </HStack>
            )}

            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'service_type') && (
              <HStack
                style={{
                  order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'service_type'),
                }}
              >
                <XSelect
                  searchable
                  label={'Service Type'}
                  error={errors.service_type}
                  value={data.service_type}
                  onInit={(callback) => {
                    axios
                      .post('/select/meta-options', {
                        entity_name: 'leads',
                        column_name: 'service_type',
                      })
                      .then((response) => {
                        callback(response.data)
                      })
                      .catch(() => {
                        callback([])
                      })
                  }}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        visa_type: value,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'alternate_phone') && (
              <HStack
                style={{
                  order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'alternate_phone'),
                }}
              >
                <XPhoneInput
                  label={'Alternate Phone'}
                  error={errors.alternate_phone}
                  dialCode={data.country_calling_code}
                  countryCode={data.country_code}
                  initialValue={data.alternate_phone}
                  onChange={(number, dialCode, countryCode, country) => {
                    setData((prevData) => ({
                      ...prevData,
                      alternate_phone: number,
                      country_calling_code: dialCode,
                      country_code: countryCode,
                      country: country,
                    }))
                  }}
                  checkMobileRemoteValidation={true}
                ></XPhoneInput>
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'b2b_relationship_manager_id') && (
              <HStack
                style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'b2b_relationship_manager_id') }}
              >
                <XSelect
                  clearable={true}
                  label={'Relationship Manager'}
                  error={errors.b2b_relationship_manager_id}
                  value={data.b2b_relationship_manager_id}
                  data={relationshipManagers}
                  onChange={(value) => {
                    setData((prevData) => ({
                      ...prevData,
                      b2b_relationship_manager_id: value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'country') && (
              <HStack
                style={{
                  order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'country'),
                }}
              >
                <XSelect
                  searchable
                  label={'Country'}
                  error={errors.country}
                  value={data.country_code}
                  data={countries}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        country_code: value,
                        country: countries.find((country) => country.value === value)?.label as string,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
          </div>
        </VStack>
        {categoryData.travel_details && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Travel Details
            </Typography>

            <div className="grid grid-cols-2 w-full gap-4 items-start p-[1px]">
              {getSelectedOfFieldByLabel(fieldsData, 'travel_details', 'travel_country') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'travel_details', 'travel_country'),
                  }}
                >
                  <XSelect
                    clearable={true}
                    searchable
                    label={'Travel Country'}
                    error={errors.travel_country}
                    value={data.travel_country_code}
                    data={countries}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          travel_country_code: value,
                          travel_country: countries.find((country) => country.value === value)?.label as string,
                        }))
                      }
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'travel_details', 'travel_start_date') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'travel_details', 'travel_start_date'),
                  }}
                >
                  <XDatePicker
                    clearable
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={
                      data.travel_start_date ? moment(data.travel_start_date as string, 'YYYY-MM-DD').toDate() : null
                    }
                    width="100%"
                    placeholder="DD/MM/YYYY"
                    valueFormat={'DD/MM/YYYY'}
                    label={'Travel Start Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          travel_start_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          travel_start_date: '',
                        }))
                      }
                    }}
                    error={errors.travel_start_date}
                  />
                </HStack>
              )}
            </div>
          </VStack>
        )}
        {categoryData.passport_details && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Passport Details
            </Typography>

            <div className="grid grid-cols-2 w-full gap-4 items-start p-[1px]">
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_number') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_number'),
                  }}
                >
                  <XTextInput
                    data-autofocus
                    label={'Passport Number'}
                    error={errors.passport_number}
                    value={data.passport_number}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        passport_number: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_nationality') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_nationality'),
                  }}
                >
                  <XTextInput
                    label={'Passport Nationality'}
                    error={errors.passport_nationality}
                    value={data.passport_nationality}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        passport_nationality: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_expiry_date') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_expiry_date'),
                  }}
                >
                  <XDatePicker
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={
                      data.passport_expiry_date
                        ? moment(data.passport_expiry_date as string, 'YYYY-MM-DD').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="DD/MM/YYYY"
                    valueFormat={'DD/MM/YYYY'}
                    label={'Passport Expiry Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          passport_expiry_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      }
                    }}
                    error={errors.passport_expiry_date}
                  />
                </HStack>
              )}

              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_issue_date') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_issue_date'),
                  }}
                >
                  <XDatePicker
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={
                      data.passport_issue_date
                        ? moment(data.passport_issue_date as string, 'YYYY-MM-DD').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="DD/MM/YYYY"
                    valueFormat={'DD/MM/YYYY'}
                    label={'Passport Issue Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          passport_issue_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      }
                    }}
                    error={errors.passport_issue_date}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_place_of_issue') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_place_of_issue'),
                  }}
                >
                  <XTextInput
                    label={'Passport Issue Place'}
                    error={errors.passport_issue_place}
                    value={data.passport_issue_place}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        passport_issue_place: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_birth_place') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_birth_place'),
                  }}
                >
                  <XTextInput
                    label={'Passport Place of Birth'}
                    error={errors.passport_birth_place}
                    value={data.passport_birth_place}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        passport_birth_place: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'previous_passport_number') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'previous_passport_number') }}
                >
                  <XTextInput
                    label={'Previous Passport Number, if any'}
                    error={errors.previous_passport_number}
                    value={data.previous_passport_number}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        previous_passport_number: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
            </div>
          </VStack>
        )}
        {categoryData.work_experience && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Work Experiences
            </Typography>
            <VStack spacing="12px">
              <HStack spacing="12px" alignItems="flex-start">
                <HStack>
                  <XTextInput
                    data-autofocus
                    label={'Company Name'}
                    error={errors.work_experience_one_company_name}
                    value={data.work_experience_one_company_name}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        work_experience_one_company_name: event.target.value,
                      }))
                    }}
                  />
                </HStack>
                <HStack>
                  <XTextInput
                    label={'Job Title'}
                    error={errors.work_experience_one_job_title}
                    value={data.work_experience_one_job_title}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        work_experience_one_job_title: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              </HStack>
              <VStack spacing="12px" alignItems="flex-start">
                <HStack>
                  <Divider variant={'dotted'} color={XelaColor.Gray11}></Divider>
                </HStack>
                <HStack>
                  <HStack>
                    <Checkbox
                      label={'I am currently working in this role.'}
                      color={'blueX'}
                      checked={data.work_experience_one_is_still_working}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_is_still_working: event.target.checked,
                        }))
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
              <HStack spacing="12px" alignItems="flex-start">
                <HStack>
                  <XDatePicker
                    dateParser={(dateString) => {
                      return moment(dateString, 'MM/YYYY').toDate()
                    }}
                    value={
                      data.work_experience_one_start_date
                        ? moment(data.work_experience_one_start_date as string, 'YYYY-MM').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="MM/YYYY"
                    valueFormat={'MM/YYYY'}
                    label={'Start Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_start_date: moment(value).format('YYYY-MM'),
                        }))
                      }
                    }}
                    error={errors.work_experience_one_start_date}
                  />
                </HStack>
                <HStack>
                  <XDatePicker
                    disabled={data.work_experience_one_is_still_working}
                    dateParser={(dateString) => {
                      return moment(dateString, 'MM/YYYY').toDate()
                    }}
                    value={
                      data.work_experience_one_end_date
                        ? moment(data.work_experience_one_end_date as string, 'YYYY-MM').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="MM/YYYY"
                    valueFormat={'MM/YYYY'}
                    label={'End Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_end_date: moment(value).format('YYYY-MM'),
                        }))
                      }
                    }}
                    error={errors.work_experience_one_end_date}
                  />
                </HStack>
              </HStack>
            </VStack>
          </VStack>
        )}
      </div>
      <HStack spacing={'12px'} justifyContent="flex-end">
        <Button
          tabIndex={-1}
          label={'Cancel'}
          variant={'secondary'}
          onClick={() => {
            dispatch(updateShowHubAddVisitorLeadModal(false))
            dispatch(updateShowHubAddLeadModal(false))
            clearErrors()
            reset()
          }}
        ></Button>
        <Button
          label={'Create'}
          onClick={() => {
            setLoading(true)
            post(route('hub.visitor-leads.quick-create'), {
              preserveState: true,
              preserveScroll: true,
              onFinish: () => {
                setLoading(false)
              },
            })
          }}
        ></Button>
      </HStack>
    </div>
  )
}

export default AddNewVisitorLeadFromHubForm
